/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import logo from "assets/images/IOCLogo.webp";
import headerImage from "assets/images/HeaderRight.webp";
import barOpen from "assets/images/barOpen.svg";
import barClose from "assets/images/barClose.svg";
import GlobalService from "services/GlobalService";
import { resturls } from "globals/utils/apiurls";
import style from "./scss/header.module.scss";
import { Button, Menu, Segment, TransitionablePortal } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { mediaBreakpoint } from "globals/utils/globalVariables";

const Header = ({ t }) => {
  let history = useHistory();
  const [activeItem, setActiveItem] = useState(
    window.location.href.split("/").pop()
  );
  const [userData, setUserData] = useState(null);
  const headerRef = useRef();
  const [open, setOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const location = useLocation();

  const fetchUserDtails = () => {
    const obj = {};
    GlobalService.generalSelect(
      (respdata) => {
        setUserData(respdata);
      },
      `${resturls.isUserPresent}`,
      obj,
      "GET",
      "pro"
    );
  };

  useEffect(() => {
    fetchUserDtails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuList = [
    "home",
    "about",
    "IOAC",
    "product",
    "homeSchooling",
    "services",
    "socialConnect",
    "howWeWork",
    // "blog",
  ];

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        headerRef.current.style.top = "-50%";
      } else {
        // if scroll up show the navbar
        headerRef.current.style.top = "0";
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      setActiveItem("home");
    } else if (path === "/parents") {
      setActiveItem("homeSchooling");
    } else {
      setActiveItem(path.split("/").pop());
    }
  }, [location]);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.innerWidth > mediaBreakpoint.tabletScreen
    ) {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const handleItemClick = (value) => {
    setActiveItem(value);
    setOpen(false);
    if (value === "homeSchooling") {
      history.push("/parents");
    } else {
      history.push(`/${value}`);
    }
  };

  const renderMenu = () => (
    <>
      {React.Children.toArray(
        menuList.map((menuName) => (
          <>
            <Menu.Item
              className={style.menuName}
              name={t(menuName)}
              color={activeItem === menuName ? "orange" : "white"}
              active={activeItem === menuName}
              onClick={() => handleItemClick(menuName)}
            />
          </>
        ))
      )}
    </>
  );

  const renderResponsiveMenuSection = () => (
    <>
      <Menu className={style.menu} fluid vertical>
        {renderMenu()}
      </Menu>
      <Button
        className={style.mobileSignupBtn}
        color="orange"
        content={userData && userData.userPresent ? "Dashboard" : "Login"}
        onClick={handleRedirect}
      />
    </>
  );

  const handleRedirect = () => {
    if (userData && userData?.userPresent) {
      window.open("https://pro.imageofachild.ai/corporate/dashboard", "_blank");
    } else {
      window.open("https://pro.imageofachild.ai/login", "_blank");
    }
  };

  return (
    <>
      <div>
        <div ref={headerRef} className={style.headerWrapper}>
          <div className={style.header}>
            <div className={style.iocHeaderLogoWrapper}>
              <img
                className={style.iocHeaderLogo}
                src={logo}
                alt="headerLogo"
              />
              <span>IMAGE OF A CHILD</span>
            </div>
            <Menu className={style.headerMenuWrapper} pointing secondary>
              {React.Children.toArray(
                menuList?.map((menuName) => (
                  <>
                    <Menu.Item
                      className={style.menuName}
                      name={t(menuName)}
                      color={activeItem === menuName ? "orange" : "white"}
                      active={activeItem === menuName}
                      onClick={() => handleItemClick(menuName)}
                    />
                  </>
                ))
              )}
            </Menu>
            <div className={style.signupBtnWrapper}>
              <Button
                className={style.signupBtn}
                color="orange"
                content={
                  userData && userData.userPresent ? "Dashboard" : "Login"
                }
                onClick={handleRedirect}
              />
            </div>
            <div className={open ? style.barIconClose : style.barIconOpen}>
              {!open ? (
                <>
                  <img
                    onClick={() => setOpen(!open)}
                    src={barOpen}
                    alt="barOpen"
                  />
                </>
              ) : (
                <>
                  <img
                    onClick={() => setOpen(!open)}
                    src={barClose}
                    alt="barClose"
                  />
                </>
              )}
            </div>
            <TransitionablePortal
              transition={{ animation: "fade left", duration: 250 }}
              onClose={() => setOpen(false)}
              open={open}
            >
              <Segment className={style.menuWrapper}>
                {renderResponsiveMenuSection()}
              </Segment>
            </TransitionablePortal>
          </div>
        </div>
        <div
          className={`${
            lastScrollY < 100 ? style.headerRight : style.hideImage
          }`}
        >
          <img className={style.headerImg} src={headerImage} alt="headerImg" />
        </div>
      </div>
    </>
  );
};

export default withTranslation("common")(Header);
