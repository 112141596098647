import { mediaBreakpoint } from "globals/utils/globalVariables";
import React, { useRef } from "react";
import styles from "./scss/HomeScreen.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Grid, GridColumn, Segment, Header } from "semantic-ui-react";
import rectangle from "assets/images/home/rectangle.webp";
import frame1mbl from "assets/images/home/frame1mbl.webp";
import frame2mbl from "assets/images/home/frame2mbl.webp";
import vector from "assets/images/home/Vector.webp";
import Slider from "react-slick";
import { educationModelContent, platFormContent } from "./HomeScreenData";
import HTMLReactParser from "html-react-parser";
import arrowLeft from "assets/images/home/ArrowCircleLeft.webp";
import arrowright from "assets/images/home/ArrowCircleRight.webp";
import feature3 from "assets/images/home/feature3.webp";
import feature4 from "assets/images/home/feature4.webp";

const Features = ({
  windowWidth,
  setActiveItem,
  activeItem,
  activePlatformItem,
  setPlatformActiveItem,
}) => {
  const sliderRef1 = useRef();
  const sliderRef2 = useRef();
  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  const isTablet = window.innerWidth < mediaBreakpoint.tablet;
  const viewBox = isTablet ? "0 0 100% 848" : "0 0 392 848";
  const renderFeatureMobileView = () => (
    <svg
      width="100%"
      height="848"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <foreignObject width={isTablet ? "100%" : "392"} height="848">
        <div className={styles.featureMobile}>
          <img src={frame1mbl} alt="frame1mbl" />
          <img src={frame2mbl} alt="frame2mbl" />
        </div>
      </foreignObject>
      <foreignObject width={isTablet ? "100%" : "392"} height="848">
        <div className={styles.featureContentMobile}>
          <div className={styles.eduModalMobile}>
            <div className={styles.educontentMblWrapper}>
              <img src={rectangle} alt="rectangle" />
              <Segment className={styles.eduSegemtMbl}>
                <Header className={styles.eduSegmentMblHeader}>
                  Education Model
                </Header>
                <div className={styles.eduContent}>
                  <img
                    src={vector}
                    className={styles.vectorImgMbl}
                    alt="vector"
                  />
                  <Slider
                    {...settings}
                    ref={sliderRef1}
                    className={styles.slider}
                  >
                    {educationModelContent.map((data) => {
                      return (
                        <div key={data.id} className={styles.sliderDiv}>
                          <p className={styles.eduActiveHeaderMbl}>
                            {data.title}
                          </p>
                          <ul className={styles.ulTag}>
                            {data.content.map((ele) => (
                              <li key={ele.title}>{HTMLReactParser(ele)}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    })}
                  </Slider>
                  <div className={styles.divWrapper}>
                    <div className={styles.childDiv}>
                      <LazyLoadImage
                        onClick={() => sliderRef1.current.slickPrev()}
                        className={styles.leftArrowImage}
                        src={arrowLeft}
                      />
                      <LazyLoadImage
                        onClick={() => sliderRef1.current.slickNext()}
                        className={styles.rightArrowImage}
                        src={arrowright}
                      />
                    </div>
                  </div>
                </div>
              </Segment>
            </div>
          </div>
          <div className={styles.eduModalMobile}>
            <div className={styles.educontentMblWrapper}>
              <img src={rectangle} alt="rectangle" />
              <Segment className={styles.eduSegemtMbl}>
                <Header className={styles.eduSegmentMblHeader}>Platform</Header>
                <div className={styles.eduContent}>
                  <img
                    src={vector}
                    className={styles.vectorImgMbl}
                    alt="vector"
                  />
                  <Slider
                    {...settings}
                    adaptiveHeight
                    ref={sliderRef2}
                    className={styles.slider}
                  >
                    {platFormContent.map((data) => {
                      return (
                        <div key={data.id} className={styles.sliderDiv}>
                          <p className={styles.eduActiveHeaderMbl}>
                            {data.title}
                          </p>
                          <ul className={styles.ulTag}>
                            {data.content.map((ele) => (
                              <li key={ele.title}>{HTMLReactParser(ele)}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    })}
                  </Slider>
                  <div className={styles.divWrapper}>
                    <div className={styles.childDiv}>
                      <LazyLoadImage
                        onClick={() => sliderRef2.current.slickPrev()}
                        className={styles.leftArrowImage}
                        src={arrowLeft}
                      />
                      <LazyLoadImage
                        onClick={() => sliderRef2.current.slickNext()}
                        className={styles.rightArrowImage}
                        src={arrowright}
                      />
                    </div>
                  </div>
                </div>
              </Segment>
            </div>
          </div>
        </div>
      </foreignObject>
    </svg>
  );

  const renderFeatureDesktopView = () => {
    const currentPlatformObj = platFormContent.find(
      (ele) => ele.id === activePlatformItem
    );
    const currentEduObj = educationModelContent.find(
      (ele) => ele.id === activeItem
    );
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1921 480"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <foreignObject width="1921" height="480">
          <div>
            <img src={feature4} alt="feature" className={styles.featureBg} />
            <img src={feature3} alt="feature" className={styles.featureBg} />
          </div>
        </foreignObject>
        <foreignObject width="1921" height="480">
          <Grid columns={2} className={styles.featureGrid}>
            <GridColumn className={styles.gridColumn}>
              <div className={styles.eduWrapper}>
                <div className={styles.eduContentWrapper}>
                  <Segment placeholder className={styles.eduSegmentWrapper}>
                    <Segment className={styles.eduSegment1}>
                      <Header className={styles.eduSegmentHeader}>
                        Education Model
                      </Header>
                      <ol>
                        {educationModelContent.map((ele) => (
                          <li
                            key={ele.title}
                            className={
                              activeItem === ele.id
                                ? styles.eduActiveTitle1
                                : styles.eduTitle1
                            }
                            onClickCapture={() => setActiveItem(ele.id)}
                          >
                            {ele.title}
                          </li>
                        ))}
                      </ol>
                    </Segment>
                    <Segment className={styles.eduSegment2}>
                      <img
                        src={vector}
                        className={styles.vectorImg}
                        alt="vector"
                      />
                      <p className={styles.eduActiveHeader}>
                        {currentEduObj.title}
                      </p>
                      <ul>
                        {currentEduObj.content.map((ele) => (
                          <li key={ele.title}>{HTMLReactParser(ele)}</li>
                        ))}
                      </ul>
                    </Segment>
                  </Segment>
                </div>
              </div>
            </GridColumn>
            <GridColumn className={styles.gridColumn}>
              <div className={styles.platFormWrapper}>
                <div className={styles.eduContentWrapper}>
                  <Segment placeholder className={styles.eduSegmentWrapper}>
                    <Segment className={styles.eduSegment1}>
                      <Header className={styles.eduSegmentHeader}>
                        Platform
                      </Header>
                      <ol>
                        {platFormContent.map((ele) => (
                          <li
                            key={ele.title}
                            className={
                              activePlatformItem === ele.id
                                ? styles.eduActiveTitle
                                : styles.eduTitle
                            }
                            onClickCapture={() => setPlatformActiveItem(ele.id)}
                          >
                            {ele.title}
                          </li>
                        ))}
                      </ol>
                    </Segment>
                    <Segment className={styles.eduSegment2}>
                      <img
                        src={vector}
                        className={styles.vectorImg}
                        alt="vector"
                      />
                      <p className={styles.eduActiveHeader}>
                        {currentPlatformObj.title}
                      </p>
                      <ul>
                        {currentPlatformObj.content.map((ele) => (
                          <li key={ele?.title}>{HTMLReactParser(ele)}</li>
                        ))}
                      </ul>
                    </Segment>
                  </Segment>
                </div>
              </div>
            </GridColumn>
          </Grid>
        </foreignObject>
      </svg>
    );
  };
  return (
    <>
      <div className={styles.features}>
        <h1 className={styles.featuresHeader}>Features</h1>
        {windowWidth > mediaBreakpoint.tablet ? (
          <>{renderFeatureDesktopView()}</>
        ) : (
          <>{renderFeatureMobileView()}</>
        )}
      </div>
    </>
  );
};

export default Features;
