export const protocol = "https";
export const domain = "apipro.imageofachild.ai";
export const port = "";
export const reactdomain = "pro.imageofachild.ai";
export const reactport = "";
export const reactcontext = "";
export const apicontext = "";
export const contactNumber = "919363614106";
export const appurl = "https://app.freckled.ai";
export const prourl = "https://apipro.imageofachild.ai";
export const contactMail = "contact@imageofachild.ai";
export const eventContactNo = "+91 9363614106";
export const eventContactMail = "contact@imageofachild.ai";
export const restbaseurl = `${protocol}://${domain}:${port}/${apicontext}`;
export const reacturl = `${protocol}://${reactdomain}:${reactport}${reactcontext}`;
